import React from 'react';
import styled from 'styled-components';

import Container from '../components/container';
import { P1 } from '../theme/typography';

const SuperHeader = styled.h1`
  font-family: Nielot;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-SH);
  line-height: 64px;
  line-height: 90%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: center;
  color: var(--white);

  @media (min-width: 750px) {
    letter-spacing: -0.01em;
  }
`;

const Center = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
`;

const Details = styled(P1)`
  color: var(--white);
  text-align: center;

  a {
    font-family: Nielot;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
`;

const IndexPage = () => {
  return (
    <>
      <div
        style={{ background: 'var(--accent)', height: '100vh', width: '100vw' }}
      >
        <Container
          style={{
            height: '100vh',
            width: '100vw',
          }}
        >
          <Center>
            <Col>
              <SuperHeader>Big news coming soon...</SuperHeader>
              <Details>
                For business enquiries contact{' '}
                <a href='mailto:eoghan@rocket.ie'>eoghan@rocket.ie</a>
              </Details>
            </Col>
          </Center>
        </Container>
      </div>
    </>
  );
};

export default IndexPage;
